<template>
  <div>
    <div class="seckill-list-header"></div>
    <div class="seckill-list-header-bg"></div>
    <div class="w-1200">
      <div class="mt-2 screen-box df-ac">
        <div class="flex-1 d-f flex-wrap py-2 px-2" v-for="(item,index) in seckillActivityList" :key="item.id">
          <div class="seckill-box" @click="cliickSeckillTime(item.id,index)"
               :class="seckillTimeIndex == index ? 'active' : ''">
            <div class="active-name">{{item.swapName}}</div>
            <div class="seckill-state row" v-if="index == nowSeckillActive">抢购中</div>
            <div class="seckill-state row" v-else-if="index<nowSeckillActive">已结束</div>
            <div class="seckill-state row" v-else>即将开始</div>
          </div>
        </div>
      </div>
      <div class="mt-2 seckill-goods-list d-f justify-start">
        <div class="seckill-goods-item border mb-2" v-for="item in seckillGoodsList" :key="item.id" @click="toProduct(item)">
          <img class="goods-img mb-2" v-lazy="item.slideList.img"/>
          <div class="seckill-goods-name font-s-2 text-grey in1line mb-1">{{item.title}}</div>
          <div class="seckill-price mb-1">
            促销价<span>￥{{item.seckillPrice}}</span>元
          </div>
          <div class="pirce mb-1">原价￥{{item.price}}</div>
          <el-button v-if="item.activeStatus==1" type="danger">马上抢</el-button>
          <el-button v-else-if="item.activeStatus==0" type="info">已结束</el-button>
          <el-button v-else-if="item.activeStatus==2" type="info">尚未开始</el-button>
        </div>
      </div>
      <!-- 翻页 -->
      <el-pagination class="mb-2"
        :current-page="seckillGoodsQueryInfo.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="seckillGoodsQueryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="seckillGoodsTotal"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"/>
    </div>
  </div>
</template>

<script>
import { getActivitySeckillUrl, getActivitySeckillGoodsListUrl } from '@/api/index'

export default {
  name: 'SeckillList',
  data () {
    return {
      seckillActivityList: [],
      seckillTimeIndex: 0,
      nowSeckillActive: 0,
      seckillActiveId: 0,
      seckillGoodsQueryInfo: {
        title: '',
        page: 1,
        pageSize: 10
      },
      seckillGoodsTotal:0,
      seckillGoodsList: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.getActivitySeckill()
    },
    // 获取秒杀活动场次
    async getActivitySeckill () {
      console.log(getActivitySeckillUrl)
      console.log(getActivitySeckillGoodsListUrl)
      const { data: res } = await this.$http.get(getActivitySeckillUrl)
      if (res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      this.seckillActivityList = res.data
      // 获取当前时间
      let date1 = new Date()
      // 去掉时间戳的毫秒
      let nowTime = Number(String(date1.getTime()).substring(0, 10))
      this.seckillActivityList.forEach((item, index) => {
        if (item.startAt < nowTime && item.endAt > nowTime) {
          console.log(item)
          this.seckillTimeIndex = index
          this.nowSeckillActive = index
          this.getActivitySeckillGoodsList(item.id)
          this.seckillActiveId = item.id
        }
      })
    },
    // 根据对应场次id获取商品列表
    async getActivitySeckillGoodsList (activeId, index) {
      console.log(activeId)
      const { data: res } = await this.$http.get(getActivitySeckillGoodsListUrl + activeId, { params: this.seckillGoodsQueryInfo })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.seckillGoodsList = res.data.list
      this.seckillGoodsTotal = res.data.total
      console.log(this.nowSeckillActive)
      if (index < this.nowSeckillActive) {
        // 活动已结束
        this.seckillGoodsList.forEach(item => {
          item.activeStatus = 0
        })
      } else if (index > this.nowSeckillActive) {
        // 活动未开始
        this.seckillGoodsList.forEach(item => {
          item.activeStatus = 2
        })
      } else {
        // 秒杀活动进行中
        this.seckillGoodsList.forEach(item => {
          item.activeStatus = 1
        })
      }
    },
    // 顶部秒杀场次点击事件
    cliickSeckillTime (activeId, index) {
      if (this.seckillTimeIndex === index) {
        return
      }
      this.seckillTimeIndex = index
      this.seckillGoodsQueryInfo.page = 1
      this.getActivitySeckillGoodsList(activeId, index)
      this.seckillActiveId = activeId
    },
    // 分页页码显示条数
    handleSizeChange (newSize) {
      // 把每页显示多少数据重新赋值
      this.seckillGoodsQueryInfo.pageSize = newSize
      this.getActivitySeckillGoodsList(this.seckillActiveId,this.seckillTimeIndex)
    },
    // 翻页事件
    handleCurrentChange (newPage) {
      this.seckillGoodsQueryInfo.page = newPage
      this.getActivitySeckillGoodsList(this.seckillActiveId,this.seckillTimeIndex)
    },
    // 跳转到商品详情页
    toProduct (productInfo) {
      this.$router.push(`/product?productId=${productInfo.id}&pageStatus=seckill&activeId=${this.seckillActiveId}&productStatus=${productInfo.activeStatus}`)
    },
  }
}
</script>

<style lang="less" scoped>
  .seckill-list-header{
    width: 100%;
    height: 2px;
    background-color: red;
  }
  .seckill-list-header-bg{
    width: 100%;
    height: 193px;
    background: url(../../assets/img/xianshizhekou.png) 0 0 no-repeat;
  }
  .seckill-box {

  }

  .seckill-box.active {
    color: #EF001C;
  }

  .seckill-goods-list {
    flex-flow: row wrap;

    .seckill-goods-item {
      width: 220px;
      padding: 20px;
      margin-right: 50px;

      .goods-img {
        width: 200px;
        height: 200px;
        transition: 1s;
        display: block;
        margin: 0 auto 20px;
      }

      .goods-img:hover {
        transform: scale(1.1);
      }

      .seckill-goods-name {

      }

      .seckill-price {
        text-align: center;
        font-size: 12px;

        span {
          font-size: 22px;
          font-weight: bold;
          color: #EF001C;
          vertical-align: bottom;
        }
      }

      .pirce {
        font-size: 12px;
        color: green;
        text-align: center;
      }

      .el-button {
        display: block;
        margin: 0 auto;

      }
    }

    .seckill-goods-item:nth-child(4n) {
      margin-right: 0;
    }
  }
</style>
